import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/layout';

Vue.use(VueRouter);

const IncomingUnit = () => import('views/incoming-unit/incoming-unit');
const User = () => import('@/views/user');
const Login = () => import('@/views/user/login/login');
const ForgetPassword = () =>
  import('@/views/user/forget-password/forget-password');
const Register = () => import('@/views/user/register/register');

const HomeMain = () => import('views/main/home-main/home-main.vue');
const MyAccount = () => import('views/main/my-account/my-account');
const ExchangeService = () =>
  import('views/main/exchange-service/exchange-service');
const CurrencyHoliday = () =>
  import('@/views/main/currency-holiday/currency-holiday');
const SystemSettings = () =>
  import('@/views/main/system-settings/system-settings');
const SignAgreement = () =>
  import('@/views/main/sign-agreement/sign-agreement.vue');
const SignAgreementNext = () =>
  import('@/views/main/sign-agreement-next/sign-agreement-next.vue');
// 查看明细
const CheckDetail = () => import('@/views/main/check-detail/check-detail.vue');
// 提现
const Withdraw = () => import('@/views/main/withdraw/withdraw.vue');
// 授信额度详情页面
const CreditRecordDetail = () =>
  import(
    '@/views/main/home-main/components/credit-record-detail/credit-record-detail.vue'
  );

const routes = [
  {
    path: '/user',
    component: User,
    children: [
      {
        path: 'forget-password',
        component: ForgetPassword,
      },
      {
        path: 'register',
        component: Register,
      },
    ],
  },
  {
    path: '/user/login',
    component: Login,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home-main',
    children: [
      {
        path: 'home-main',
        component: HomeMain,
      },
      {
        path: 'my-account',
        component: MyAccount,
      },
      {
        path: 'exchange-service',
        component: ExchangeService,
      },
      {
        path: 'currency-holiday',
        component: CurrencyHoliday,
      },
      {
        path: 'funds-inflow',
        component: () => import('@/views/main/funds-inflow/funds-inflow'),
      },
      {
        path: 'issue-details',
        component: () => import('@/views/main/funds-inflow/issue-details'),
      },
      {
        path: 'system-settings',
        component: SystemSettings,
      },
      // 查看明细
      {
        path: 'check-detail',
        component: CheckDetail,
      },
      // 提现
      {
        path: 'withdraw',
        component: Withdraw,
      },
      {
        path: 'credit-record-detail',
        component: CreditRecordDetail,
      },
    ],
  },
  {
    path: '/incoming-unit',
    component: IncomingUnit,
  },
  { path: '/sign-agreement', component: SignAgreement },
  { path: '/sign-agreement-next', component: SignAgreementNext },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;
