import axios from 'axios';
import { Message } from 'element-ui';
import config from '../config';
import storage from './storage';
import download from './download';
import router from '@/router';

const LOGIN_EXPIRE = '登录过期';
const NETWORK_ERROR = '网络请求异常，请稍后重试';

export const ERR_OK = 100200;

let isCancel = false;

// 创建 axios 实例对象，添加全局配置
const service = axios.create({
  baseURL: config.baseApi,
  timeout: 30000,
});

// 在请求或者响应被then或catch处理前拦截它们。配置token,header使得不必每次请求接口都要加token
service.interceptors.request.use(
  config => {
    const { headers, params, data } = config;
    const token = storage.getItem('token');
    if (typeof headers === 'object' && token) {
      headers.Authorization = token;
    }
    if (params && params.current) {
      params.pageNum = params.current;
      delete params.current;
    }
    if (data && data.current) {
      data.pageCurrent = data.current;
      delete data.current;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// 响应拦截
service.interceptors.response.use(
  res => {
    // 拦截下载，如果返回的是二进制文件流，会直接下载
    if (!download(res)) {
      return;
    }
    const { code, dataSet, msg, pageNum, pageSize, totalNumber } = res.data;
    if (code === ERR_OK) {
      if (totalNumber || totalNumber === 0) {
        return {
          code,
          dataSet,
          pages: {
            pageNum,
            pageSize,
            total: totalNumber,
          },
        };
      }
      return dataSet || msg;
    } else if (code === '100401') {
      Message.error(LOGIN_EXPIRE);
      router.push('/user/login');
      //setTimeout(() => {
      //  window.location.href = '/login';
      //}, 1500);
      return Promise.reject(LOGIN_EXPIRE);
    } else {
      Message.error(msg || NETWORK_ERROR);
      return Promise.reject(msg || NETWORK_ERROR);
    }
  },
  error => {
    if (error.response.status === 401) {
      if (!isCancel) {
        Message.error(error.response.data.msg);
        router.push('/user/login');
        isCancel = true;
      }
      return;
    }
    Message.error(error.response.data?.msg || '网络错误，请稍后再试');
    return Promise.reject(error.response);
  },
);

export default service;
