/**
 * 环境配置封装
 */
const env = process.env.VUE_APP_NODE_ENV || 'prod';

const envConfig = {
  dev: {
    baseApi: '/api',
    mockApi: '',
  },
  test: {
    baseApi: '/core-api',
    mockApi: '',
  },
  prod: {
    baseApi: '/coreApi',
    mockApi: '',
  },
};

export default {
  env,
  mock: false,
  namespace: 'self_service_web',
  ...envConfig[env],
};
