import numeral from 'numeral';
import Vue from 'vue';

const isFalse = value => {
  if (!value) return false;
  return !value;
};
// 格式化金额
const formatAmount = amount => {
  if (amount == null) return '';
  if (amount || amount === 0) return numeral(amount).format('0,0.00');
  return 0;
};
//格式化数量
const formatNumber = number => {
  if (number) {
    return numeral(number).format('0,0');
  }
  return 0;
};

const resetAmount = amount => {
  if (amount) {
    return numeral(amount).format('0.00');
  }
  return 0;
};

const formatSelectList = (list, textKey = 'name', valueKey = 'value') => {
  return list.map(item => ({
    text: item[textKey]?.trim(),
    value: item[valueKey],
  }));
};

// 格式化省市区数据
const transObject = function (tableData, keys, keys2) {
  let hashTable = {},
    res = [];
  for (let i = 0; i < tableData.length; i++) {
    // 先找顶层(省)
    if (!hashTable[tableData[i][keys[0]]]) {
      let len = res.push({
        label: tableData[i][keys2[0]],
        value: tableData[i][keys[0]],
        children: [],
      });
      // 在这里要保存key对应的数组序号,不然还要涉及到查找
      hashTable[tableData[i][keys[0]]] = { pos: len - 1 };
    }
    if (!hashTable[tableData[i][keys[0]]][tableData[i][keys[1]]]) {
      let len = res[hashTable[tableData[i][keys[0]]].pos].children.push({
        label: tableData[i][keys2[1]],
        value: tableData[i][keys[1]],
        children: [],
      });
      hashTable[tableData[i][keys[0]]][tableData[i][keys[1]]] = {
        pos: len - 1,
      };
    }
    res[hashTable[tableData[i][keys[0]]].pos].children[
      hashTable[tableData[i][keys[0]]][tableData[i][keys[1]]].pos
    ].children.push({
      value: tableData[i][keys[2]],
      label: tableData[i][keys2[2]],
    });
  }
  return res;
};

// 只能输入中文、英文、数字、半角符号、空格，前后不能有空格
export const commonReg =
  /^(?! )(?!.* $)[a-zA-Z0-9\u4e00-\u9fa5 ,/#$%&@.!?'"(){}[\]-]*$/;
export const commonReg1 = /^[A-Z0-9]*$/;

export const commonCheck = (rule, value, callback) => {
  if (!value) {
    callback(new Error(Vue.i18n.translate('COMMON_ERR1')));
  } else if (!commonReg.test(value)) {
    callback(new Error(Vue.i18n.translate('COMMON_ERR2')));
  } else {
    callback();
  }
};

function my_debounce(fn, wait) {
  let timer = null;
  wait = wait || 60;
  return function () {
    let that = this;
    if (timer !== null) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(that);
    }, wait);
  };
}
const raw = {
  transObject,
  isFalse,
  formatAmount,
  resetAmount,
  formatSelectList,
  formatNumber,
  my_debounce,
  commonReg,
};

export default raw;
