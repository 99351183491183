<template>
  <el-select
    size="small"
    class="language-select"
    v-model="language"
    @change="setLanguage"
  >
    <el-option label="English" value="en"></el-option>
    <el-option label="中文" value="cn"></el-option>
  </el-select>
</template>

<script>
import storage from '@/utils/storage';

export default {
  data() {
    return {
      language: '',
    };
  },
  created() {
    this.language = storage.getItem('userLanguage') || 'en';
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.set(lang);
      storage.setItem('userLanguage', lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-select {
  width: 100px;
  height: 40px;
}
</style>
