<template>
  <div style="width: 100%; height: 100%">
    <div class="headerBox">
      <div class="imgBox">
        <img alt="icon" :src="logo" />
      </div>
      <div class="info">
        <div class="userInfo">
          <span>
            {{ $t('index_message2') }} , {{ $store.state.companyName }}
          </span>
          <span class="username">
            {{ $store.state.username }}
          </span>
          <el-dropdown
            style="display: flex; align-items: center"
            @command="handleCommand"
          >
            <img src="./image/avatar.png" alt="头像" style="cursor: pointer" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="password">
                {{ $t('index_message3') }}
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                {{ $t('index_message4') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <LanguageButton style="margin-left: 20px" />
        </div>
      </div>
      <!--  修改密码弹窗  -->
      <el-dialog
        title="修改密码"
        width="500px"
        append-to-body
        :visible.sync="dialog"
        :close-on-click-modal="false"
        @close="handleDialogClose"
      >
        <div class="form-wrapper">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            size="small"
            label-width="100px"
          >
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input type="password" v-model="form.oldPassword" />
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input type="password" v-model="form.newPassword" />
            </el-form-item>
            <el-form-item label="确认新密码" prop="repeatPassword">
              <el-input type="password" v-model="form.repeatPassword" />
            </el-form-item>
            <el-form-item label="验证码" prop="verifyCode">
              <el-input v-model="form.verifyCode" />
              <img
                class="verifyCodeBox"
                :src="verify_code_url"
                alt="verifyCode"
                @click="_getVerifyCode"
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer">
          <el-button @click="dialog = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="handleChangePassword">
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>
    <el-container class="homeBox">
      <el-aside style="width: 208px">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#001529"
          text-color="#fff"
          active-text-color="#409eff"
        >
          <router-link to="home-main">
            <el-menu-item index="1">
              <template slot="title">
                <i class="m-icon icon iconfont icon-shouye"></i>
                <span>{{ $t('index_menu1') }}</span>
              </template>
            </el-menu-item>
          </router-link>
          <router-link to="/my-account">
            <el-menu-item index="2">
              <template slot="title">
                <i class="m-icon icon iconfont icon-wodezhanghu"></i>
                <span>{{ $t('index_menu2') }}</span>
              </template>
            </el-menu-item>
          </router-link>
          <router-link to="/exchange-service">
            <el-menu-item index="3">
              <template slot="title">
                <i
                  class="m-icon icon iconfont icon-chukoutuishui_huanhuichengben"
                ></i>
                <span>{{ $t('index_menu3') }}</span>
              </template>
            </el-menu-item>
          </router-link>
          <router-link to="/withdraw">
            <el-menu-item index="4">
              <template slot="title">
                <i class="m-icon icon iconfont icon-fukuan"></i>
                <span>{{ $t('index_menu4') }}</span>
              </template>
            </el-menu-item>
          </router-link>
          <router-link to="/currency-holiday">
            <el-menu-item index="5">
              <template slot="title">
                <i class="m-icon icon iconfont icon-wancheng"></i>
                <span>{{ $t('index_menu5') }}</span>
              </template>
            </el-menu-item>
          </router-link>
          <!--          <router-link to="/funds-inflow">
            <el-menu-item index="7">
              <template slot="title">
                <i class="m-icon icon iconfont icon-wancheng"></i>
                <span>{{ $t('index_menu7') }}</span>
              </template>
            </el-menu-item>
          </router-link>-->
          <router-link to="/system-settings">
            <el-menu-item index="6">
              <template slot="title">
                <i class="m-icon icon iconfont icon-jinggao_o"></i>
                <span>{{ $t('index_menu6') }}</span>
              </template>
            </el-menu-item>
          </router-link>
        </el-menu>
      </el-aside>
      <div style="width: calc(100% - 208px)">
        <router-view />
      </div>
    </el-container>
  </div>
</template>

<script>
import { logout, modifyPassword, getVerifyCode } from './api';
import logo from '@/static/images/main-logo.png';
import LanguageButton from '@/components/LanguageButton.vue';

export default {
  name: 'my-header',
  data() {
    return {
      logo,
      verify_code_url: '',
      dialog: false,
      form: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        verifyCode: '',
      },
    };
  },
  computed: {
    rules() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('register_form4_placeholder')));
        } else {
          if (this.form.repeatPassword !== '') {
            this.$refs.form.validateField('repeatPassword');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('register_form5_placeholder')));
        } else if (value !== this.form.newPassword) {
          callback(new Error(this.$t('register_form5_validate')));
        } else {
          callback();
        }
      };
      return {
        oldPassword: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        repeatPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        verifyCode: [{ required: true, trigger: 'blur' }],
      };
    },
  },
  methods: {
    handleDialogClose() {
      this.$refs.form.resetFields();
    },
    handleCommand(command) {
      if (command === 'logout') {
        try {
          this.handleLogout();
        } catch (err) {
          console.error(err);
        }
      } else if (command === 'password') {
        this.dialog = true;
        this._getVerifyCode();
      }
    },
    async _getVerifyCode() {
      this.verify_code_url = await getVerifyCode({});
    },
    async handleChangePassword() {
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          const { oldPassword, newPassword, verifyCode } = this.form;
          await modifyPassword({ oldPassword, newPassword, code: verifyCode });
          this.$message.success(this.$t('index_message'));
          this.dialog = false;
          await this._logout();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async handleLogout() {
      try {
        await logout();
        this.$message.success(this.$t('exit_success'));
        await this._logout();
      } catch (err) {
        console.error(err);
      }
    },
    async _logout() {
      this.$store.commit('clearAllStore');
      await this.$router.push('/user/login');
    },
  },
  components: {
    LanguageButton,
  },
};
</script>

<style scoped lang="scss">
.verifyCodeBox {
  width: 104px;
  height: 37px;
  margin-top: 10px;
  cursor: pointer;
}
.headerBox {
  box-sizing: border-box;
  padding: 0 43px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  line-height: 48px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  .imgBox {
    width: 126px;
    height: 74%;
    img {
      height: 100%;
    }
  }
  .info {
    display: flex;
    align-items: center;
    .userInfo {
      display: flex;
      .username {
        margin-right: 10px;
      }
    }
  }
  .title {
    color: #147fd2;
    font-size: 28px;
    font-weight: bold;
  }
  .isActive {
    color: #147fd2;
  }
  .btnArea {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 0;
    span {
      cursor: pointer;
      font-weight: bold;
    }
    span:nth-child(2) {
      font-size: 14px;
      margin-left: 20px;
    }
  }
}
.homeBox {
  width: 100%;
  min-height: calc(100% - 48px);
  .el-submenu {
    i {
      margin-right: 10px;
    }
  }
  .m-icon {
    margin-right: 8px;
  }
}
::v-deep .el-aside {
  overflow: hidden;
}
::v-deep .el-menu {
  height: 100%;
}
</style>
