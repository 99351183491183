import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import storage from '@/utils/storage';
import util from '@/utils/utils';
import {
  Button,
  Select,
  Card,
  Divider,
  Dropdown,
  Drawer,
  Form,
  DropdownMenu,
  DropdownItem,
  FormItem,
  Table,
  TableColumn,
  Option,
  DatePicker,
  Steps,
  Step,
  Menu,
  Message,
  Dialog,
  Input,
  MenuItem,
  Container,
  Aside,
  Submenu,
  MenuItemGroup,
  Pagination,
  Radio,
  Tabs,
  TabPane,
  Upload,
  RadioGroup,
  Checkbox,
  Popconfirm,
  Descriptions,
  DescriptionsItem,
  InputNumber,
  Loading,
} from 'element-ui';
//引入阿里图标库
import './assets/font/iconfont.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/index.scss';
//antd
import './plugins/ant-design-vue.js';

Vue.prototype.$utils = util;
Vue.prototype.$storage = storage;
Vue.use(Loading);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Popconfirm);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(Aside);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(Container);
Vue.use(Button);
Vue.use(Select);
Vue.use(Card);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(TableColumn);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Menu);
Vue.prototype.$message = Message;
Vue.use(Dialog);
Vue.use(Input);
Vue.use(MenuItem);
Vue.use(Table);
Vue.use(RadioGroup);
Vue.use(InputNumber);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
