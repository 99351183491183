import Vue from 'vue';
import Vuex from 'vuex';
import storage from '@/utils/storage';
import vuexI18n from 'vuex-i18n';
import translationsEn from './languages/en';
import translationsCn from './languages/cn';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    token: storage.getItem('token') || '',
    menus: storage.getItem('menus') || [],
    username: storage.getItem('username') || '',
    companyName: storage.getItem('companyName') || '',
    companyId: storage.getItem('companyId') || '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setMenus(state, menus) {
      state.menus = menus;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setCompanyName(state, name) {
      state.companyName = name;
    },
    setCompanyId(state, name) {
      state.setCompanyId = name;
    },
    clearAllStore(state) {
      state.token = '';
      state.menus = [];
      state.username = '';
      state.companyName = '';
      state.companyId = '';
      storage.clearAll();
    },
  },
  actions: {},
  modules: {},
});

Vue.use(vuexI18n.plugin, store);

// 添加翻译到 vuex-i18n. 这里的 'en' 和 'cn' 是语言环境的标识符
Vue.i18n.add('en', translationsEn);
Vue.i18n.add('cn', translationsCn);

// 设置默认语言
const userLanguage = storage.getItem('userLanguage');
Vue.i18n.set(userLanguage || 'en');

store.subscribe(({ type, payload }) => {
  switch (type) {
    case 'setMenus':
      storage.setItem('menus', payload);
      break;
    case 'setUsername':
      storage.setItem('username', payload);
      break;
    case 'setToken':
      storage.setItem('token', payload);
      break;
    case 'setCompanyName':
      storage.setItem('companyName', payload);
      break;
    case 'setCompanyId':
      storage.setItem('companyId', payload);
      break;
  }
});

export default store;
